import { InjectionToken } from '@angular/core';

export interface ModalData {
	modalType?: string,
	title?: string,
	backBtn?: boolean,
	closeBtn?: boolean,
	labelBtn?: string,
	header: boolean,
	disableClose?: boolean,
	modalContainerClasses: string,
	modalContentCasses: string
}


export const MODAL_DATA = new InjectionToken<ModalData>('MODAL_DATA');