import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';
import { AppRoutes } from '@utils/routes';

export const SubDomainGuard: CanMatchFn = (route, segments): boolean => {
	//for test on android emulator in devTools comment all lines code
	let document = inject(DOCUMENT);
	if (document.location.hostname !== environment.host) {
		let pattern = new RegExp("(" + AppRoutes.UKNOW + "\/)+.")
		if (pattern.test(document.location.pathname)) {
			let router = inject(Router);
			router.navigate([document.location.pathname])
		} else {
			window.location.href = `${document.location.protocol}//${environment.host}${environment.host === 'localhost' ? ':4200' : ''}${AppRoutes.BASE}`;
		}
		return false;
	}
	return true;
}