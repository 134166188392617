import { Injectable, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Injectable({
    providedIn: 'root'
})
export class FullscreenService {
    elem: any;
    constructor(
        @Inject(DOCUMENT) private document: any
    ) {
        this.elem = document.documentElement;
    }

    @HostListener('document:fullscreenchange', ['$event'])
    @HostListener('document:webkitfullscreenchange', ['$event'])
    @HostListener('document:mozfullscreenchange', ['$event'])
    @HostListener('document:MSFullscreenChange', ['$event'])

    /* Open fullscreen */
    openFullscreen() {
        if (this.elem.requestFullscreen) {
            this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
            /* Firefox */
            this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
            /* IE/Edge */
            this.elem.msRequestFullscreen();
        }
    }
    /* Close fullscreen */
    closeFullscreen() {
        if (this.chkScreenMode())
            if (this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
    }

    fullscreenmodes() {
        this.chkScreenMode();
    }

    private chkScreenMode() {
        if (document.fullscreenElement) {
            return true;
        } else {
            return false;
        }
    }
}