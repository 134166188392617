import packageJson from '../../package.json';
export const environment = {
	version: packageJson.version,
	production: false,
	test: true,
	develop: false,
	environment: 'test',
	service: 'test',
	wsUrl: 'wss://apimgmt.synapsia.ai/maestroWebtest',
	host: 'apptest.mymaia.ai',
};