import { ActionReducerMap } from '@ngrx/store';
import { IMaiaState } from './app.interface';
import { ChatReducer } from './reducers/chat.reducer';
import { UserReducer } from './reducers/user.reducer';
import { PersonalSettingsReducer } from './reducers/personalSettings.reducer';
import { ContactsReducer } from './reducers/contacts.reducer';
import { SearchReducer } from './reducers/search.reducer';
import { PersonalGraphReducer } from './reducers/personalGraph.reducer';
import { TextToSpeechReducer } from './reducers/tts.reducer';
import { CalendarReducer } from './reducers/calendar.reducer';
import { UdriveReducer } from './reducers/udrive.reducer';
import { UknowReducer } from './reducers/uknow.reducer';

export const reducers: ActionReducerMap<IMaiaState> = {
	ChatState: ChatReducer,
	UserState: UserReducer,
	PersonalSettingsState: PersonalSettingsReducer,
	ContactsState: ContactsReducer,
	SearchState: SearchReducer,
	PersonalGraphState: PersonalGraphReducer,
	TextToSpeechState: TextToSpeechReducer,
	CalendarState: CalendarReducer,
	UdriveState: UdriveReducer,
	UknowState: UknowReducer
};
