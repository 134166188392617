import { createAction, props } from '@ngrx/store';
import { DecodedTokenModel } from '@models/decodedToken.model';

export const LogIn = createAction(
    '[StoreAuth] Login',
    props<{ email: string, password: string }>()
);
export const LogInSuccess = createAction(
    '[StoreAuth] LoginSuccess',
    props<{ userDetail: DecodedTokenModel }>()
);
export const LogInError = createAction(
    '[StoreAuth] LoginError',
    props<{ error: any }>()
);

export const LogOut = createAction(
    '[StoreAuth] LogOut'
);