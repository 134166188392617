import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Query, AiResponseModel, MessageModel, TtsSingleAudioReqModel, SharedListModel, ConvDriveCUModel, ConvDriveGetAttachmentsModel, UknowAttachByHostModel, MaiaConvModel, ConisReadModel } from '@models/chat.model';
import { environment } from '@environments/environment';
import { API_URL } from '@constants/api.constant';
import { timeout } from 'rxjs';
import { BYPASS_LOG } from '../interceptors/auth.interceptor';
import { DeleteAttachErrorModel } from '@models/udrive.model';


const service: string = window.sessionStorage.getItem('maia_service') ? window.sessionStorage.getItem('maia_service')! : environment.service;
const headers = new HttpHeaders().set('service', service);//.set('Access-Control-Allow-Origin', '*').set('ngrok-skip-browser-warning', 'edoardo');
const context = new HttpContext().set(BYPASS_LOG, true);
export const CHAT_LIST_LENGTH = 30;

@Injectable({
	providedIn: 'root',
})
export class ChatService {
	constructor(private http: HttpClient) { }
	readMaiaConv() {
		return this.http.get<MaiaConvModel>(
			API_URL.readMaiaConv,
			{ responseType: 'json' }
		)
	}

	readMaiaConvPinned() {
		return this.http.get<MaiaConvModel>(
			API_URL.readMaiaConvPinned + CHAT_LIST_LENGTH,
			{ responseType: 'json' }
		)
	}

	readMaiaConvPaged(size: number, page: number) {
		return this.http.get<MaiaConvModel>(
			`${API_URL.readMaiaConvPaged}${size}/${page}`,
			{ responseType: 'json' }
		)
	}


	query(params: Query) {
		return this.http.post<AiResponseModel>(API_URL.query, params, { headers, context, observe: 'body' }).pipe(
			timeout(300000) //5 minuti
		);
	}
	getConversationById(idConv: string) {
		return this.http.get<MessageModel[]>(
			API_URL.conversationById + idConv,
			{ responseType: 'json' }
		);
	};
	readLiveMail(idMail: string, idUser: string) {
		return this.http.post<{ [key: string]: any }>(`${API_URL.readHtmlMail}${idUser}/${idMail}`, null, { observe: 'body' });
	};
	deleteConversationById(idConv: string) {
		return this.http.delete<string>(
			API_URL.deleteConversationById + idConv,
			{ responseType: 'json' }
		)
	};
	deleteConversationList(convList: string[]) {
		return this.http.post<string>(
			API_URL.deleteConversationList,
			convList,
			{ responseType: 'json' }
		)
	}

	deleteConversationExchange(convId: string, msgId: number) {
		return this.http.delete<string>(
			`${API_URL.deleteConversationExchange}${convId}/${msgId}`,
			{ responseType: 'json' }
		)
	}

	cleanConversation(convId: string, msgsId: number[]) {
		return this.http.post<string>(
			`${API_URL.cleanConversation}${convId}/`,
			msgsId,
			{ responseType: 'json' }
		)
	}


	updateConversationTitle(idConv: string, newTitle: string) {
		return this.http.post<string>(
			API_URL.updateConversationTitle + idConv,
			{ text: newTitle },
			{ responseType: 'json' }
		)
	}

	updateConversationPinned(idConv: string, pinned: boolean) {
		return this.http.get<string>(
			`${API_URL.updateConversationPinned}${idConv}/${pinned}`,
			{ responseType: 'json' }
		)
	}


	updateAdamAnswer(idConv: string, idMess: number, msgBody: any[]) {
		return this.http.post<string>(`${API_URL.updateAdamAnswer}${idConv}/${idMess}`, msgBody, { responseType: 'json' })
	}

	getGooglePlacePhotos(idPhoto: string, width: number) {
		return this.http.get(
			`${API_URL.googlePlaceImage}${width}/${idPhoto}`,
			{ headers, context, responseType: 'text' }
		);
	}


	textToSpeech(tts: TtsSingleAudioReqModel) {
		return this.http.post<string>(`${API_URL.textToSpeech}`, tts, { headers, context, responseType: 'text' as 'json' });
	}

	blockTextToSpeech(idUser: string, requestId: string) {
		return this.http.post<any>(`${API_URL.textToSpeechBlock}${idUser}/${requestId}`, null, { responseType: 'json' }
		);
	}

	textToSpeechWeather() {
		return this.http.get(
			`${API_URL.textToSpeech}`,
			{ responseType: 'text' }
		);
	}

	readSharedChat(id: string) {
		return this.http.get<any>(
			`${API_URL.readSharedChat}${id}`,
			{ headers, context, responseType: 'json' }
		);
	}

	uKnowReadAttachments(host: string) {
		return this.http.post<UknowAttachByHostModel>(
			`${API_URL.uKnowReadAttachments}`,
			{ host },
			{ headers, context, responseType: 'json' }
		);
	}

	generateShareLink(convId: string) {
		return this.http.post(
			`${API_URL.generateShareChat}${convId}`,
			null,
			{ responseType: 'text' }
		);
	}

	getSharedConversations() {
		return this.http.get<SharedListModel[]>(
			API_URL.readSharedConversations,
			{ responseType: 'json' }
		)
	}

	deleteSharedConversation(convId: number) {
		return this.http.post(
			`${API_URL.deleteSharedConversation}${convId}`,
			null,
			{ responseType: 'text' }
		);
	}


	convDriveCreate(body: ConvDriveCUModel) {
		return this.http.post(
			API_URL.convDriveCreate,
			body,
			{ responseType: 'text' }
		)
	}
	convDriveUpdate(body: ConvDriveCUModel) {
		return this.http.post(
			API_URL.convDriveUpdate,
			body,
			{ responseType: 'text' }
		)
	}

	convDriveGetAttachments(convId: string) {
		return this.http.get<ConvDriveGetAttachmentsModel>(
			`${API_URL.convDriveGetAttachments}${convId}`,
			{ responseType: 'json' }
		)
	}

	convDriveCleanAttach(convId: string) {
		return this.http.post(
			`${API_URL.convDriveCleanAttachments}${convId}`,
			null,
			{ responseType: 'json' }
		)
	}
	convDriveDetach(detahcList: DeleteAttachErrorModel[]) {
		return this.http.post<DeleteAttachErrorModel>(
			API_URL.convDriveDetach,
			detahcList,
			{ responseType: 'json' }
		)
	}


	calculateFileEmbeddings(attachId: number) {
		return this.http.get<any>(
			API_URL.calculateFileEmbeddings + attachId,
			{ responseType: 'json' }
		)
	}
	searchMaiaConversation(searchText: string) {
		return this.http.post<MaiaConvModel>(API_URL.searchMaiaConversation, { text: searchText }, { responseType: 'json' })
	}

	conversationReadGuest(guestId: string, convId: string) {
		return this.http.get<any>(
			`${API_URL.conversetionGuest}${guestId}/${convId}`,
			{ responseType: 'json' }
		)
	}

	stopAnswer(convId: string) {
		return this.http.post<any>(
			`${API_URL.stopAnswer}${convId}`,
			{ responseType: 'json' }
		)
	}


	arraysChatAreEqual(arr1: any, arr2: any): boolean {
		if (arr1.length !== arr2.length) {
			return false;
		}
		for (let i = 0; i < arr1.length; i++) {
			delete arr1[i].lastMsgTime;
			delete arr2[i].lastMsgTime;
			if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
				return false;
			}
		}
		return true;
	}
	arrayChatEquals(firstArray: any[], second: any[]) {
		return Array.isArray(firstArray) && Array.isArray(second) && firstArray.length === second.length && firstArray.every((val, index) => val === second[index]);
	}


	//TO DEL
	// sendMail() {
	//     let path = 'assets/ResetPassword.html';
	//     this.http.get(path, { responseType: "text" }).subscribe(data => {
	//         let body = {
	//             "fromName": 'pippo',
	//             "to": ['marco.baccanelli@synapsia.ai', 'ilrossofamale@gmail.com', 'ilrossofamale@hotmail.com'],
	//             "messageText": data,
	//             "subject": 'test mail',
	//             "replyToList": null,
	//             "isBodyHtml": true
	//         }
	//         this.http.post<string>(
	//             API_URL.sendMail, body,
	//             { responseType: 'json' }
	//         ).subscribe({
	//             next: res => console.log(res),
	//             error: error => console.log(error)
	//         })
	//     });
	// }

	coinsRead() {
		return this.http.get<ConisReadModel>(API_URL.coinRead, { responseType: 'json' })
	}



}
