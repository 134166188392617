import { createReducer, on, Action } from '@ngrx/store';
import { CalendarState, initialAppState } from '../app.interface';
import * as actions from '../actions/calendar.action'
import { CalendarEventModel } from '@models/calendar.model';
import { TimeUtils } from '@utils/times';
import { v4 as uuid } from 'uuid';

export const reducer = createReducer(
	initialAppState.CalendarState,
	on(actions.SetCalendar, (state, payload): CalendarState => {
		return {
			...state,
			availCalendars: [...payload.value],
			currentCalendar: payload.value[0]
		}
	}),
	on(actions.GetEvents, (state): CalendarState => {
		return {
			...state,
			calendarWaiting: true
		}
	}),
	on(actions.SetEvents, (state, payload): CalendarState => {
		return {
			...state,
			currEventSettings: payload.value,
			calendarWaiting: false
		}
	}),
	on(actions.EditEvent, (state, payload): CalendarState => {
		let tmpEnd;
		if (payload.value.allDay) {
			tmpEnd = TimeUtils.dateAdd(payload.value.start!, 0, 0, 0, 21, 59, 59);
		} else {
			tmpEnd = payload.value.end!
		}
		let currEvent: CalendarEventModel = {
			id: payload.value.id,
			title: payload.value.title,
			start: payload.value.start!,
			end: tmpEnd,
			allDay: payload.value.allDay,
			classNames: payload.value.extendedProps['engine'],
			backgroundColor: payload.value.backgroundColor,
			borderColor: payload.value.borderColor,
			extendedProps: {
				description: payload.value.extendedProps['description'],
				engine: payload.value.extendedProps['engine'],
				dbId: payload.value.extendedProps['dbId'],
			},
		}
		return {
			...state,
			currentEvent: { ...currEvent },
			calendarWaiting: null
		}
	}),
	on(actions.AddEvent, (state, payload): CalendarState => {
		let currEvent: CalendarEventModel = {
			id: uuid(),
			title: '',
			start: new Date(payload.value),
			end: TimeUtils.dateAdd(payload.value, 0, 0, 0, 23, 59, 59),
			allDay: true,
			classNames: ['maia'],
			backgroundColor: '#007579',
			borderColor: '#007579',
			extendedProps: {
				description: '',
				engine: 'maia'
			},
		}
		return {
			...state,
			currentEvent: { ...currEvent },
			calendarWaiting: null
		}
	}),
	on(actions.CalendarApiStatus, (state, payload): CalendarState => {
		return {
			...state,
			calendarWaiting: payload.value
		}
	}),
	on(actions.SaveEvent, (state, payload): CalendarState => {
		return {
			...state,
			currentEvent: null
		}
	}),
	on(actions.resetCalendarState, (state): CalendarState => {
		return {
			...state,
			...initialAppState.CalendarState
		}
	})
)


export function CalendarReducer(state: CalendarState | undefined, action: Action): CalendarState {
	return reducer(state as CalendarState, action as Action);
}