import { TagSelectOption } from "./generic.model"

export interface SettingModel {
	id?: number;
	id_persona?: number | null;
	key: string;
	user_id: string;
	value: string
}

export enum PolicyType {
	localizationPolicy = "localizationPolicy",
	mailCalPolicy = "mailCalPolicy",
	microphonePolicy = "microphonePolicy",
	custMarkPolicy = "custMarkPolicy",
	dataThirdPartPolicy = "dataThirdPartPolicy",
}

export interface UserInformations {
	address: string | null;
	banned: boolean;
	bio: string | null;
	birthDate: string | null;
	coinsSpent: number;
	coinsTotal: number;
	country: string;
	[PolicyType.custMarkPolicy]: boolean;
	[PolicyType.dataThirdPartPolicy]: boolean;
	disabled: boolean;
	displayName: string;
	email: string;
	generationLevel: string;
	id: string;
	idRole: number;
	id_email: string;
	id_uup: string | null;
	imageUploadCount: number;
	interests: string[] | null;
	isPlus: boolean;
	isPro: boolean;
	language: string;
	[PolicyType.localizationPolicy]: boolean;
	[PolicyType.mailCalPolicy]: boolean;
	maxImageUploadCount: number;
	[PolicyType.microphonePolicy]: boolean;
	name: string;
	ocpApimSubscriptionKey: string | null;
	profilePhoto: string | null;
	sex: string;
	surname: string;
	uDriveSize: number;
}

export interface SourceManagement {
	blackList: string[];
	whiteList: string[]
}

export enum NotificationType {
	browser_notifications = "browser_notifications",
	sound_browser_notifications = "sound_browser_notifications",
	mail_notifications = "mail_notifications",
	calendar_events = "calendar_events"

}

export interface SettingNotification {
	[NotificationType.browser_notifications]: boolean;
	[NotificationType.sound_browser_notifications]: boolean;
	[NotificationType.mail_notifications]: boolean;
	[NotificationType.calendar_events]: boolean;
}


export enum SettingsKeyName {
	MAIA_APPLICATION = 'maiaApplication',
	CONVERSATION_TITLE_BY_USER_LIST = 'convTitleByUserList',
	TIMEZONE = 'timeZone',
	BANNED_CATEGORY = 'bannedCategory',
	LAST_PPG_UPDATE = 'lastPPGUpdate',
	GOOGLE_TOKEN = 'googleToken',
	GOOGLE_CALENDAR = 'googleCalendar'
}