import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as chatActions from '@store/actions/chat.action';
import * as udriveActions from '@store/actions/udrive.action';
import { ModalService } from '@services/modal.service';

import { CoinOverErrorComponent } from '../components/coin-over-error/coin-over-error.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private injector: Injector
	) { }
	store = this.injector.get(Store);
	modalService = this.injector.get(ModalService);
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				this.store.dispatch(chatActions.LoadChat({ value: false }));
				this.store.dispatch(udriveActions.loadUDrive({ value: false }));
				let errorRes = error.error;
				if (errorRes.status === 409 && errorRes.errorCode === 'COIN_OVER') {
					this.modalService.open(CoinOverErrorComponent, {
						data: {
							header: false,
						},
					})
					return of();
				} else {
					return throwError(() => error);
				}
			})
		);
	}


	handleError(error: HttpErrorResponse): Observable<never> {
		let errorRes = error.error;
		if (errorRes.status === 409 && errorRes.errorCode === 'COIN_OVER') {
			return of();
		} else {
			return throwError(() => error);
		}
	}
}