import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '@constants/api.constant';
import { CudNodeModel, PpgData, PpgDictionaryModel } from '@models/personalGraph.model';

@Injectable({
	providedIn: 'root',
})
export class PersonalGraphService {
	constructor(private http: HttpClient) { }


	getGraphData(userOid: string) {
		return this.http.get<PpgData>(
			API_URL.readGraphById + userOid,
			{ responseType: 'json' }
		);
	}

	getlastPpgEdit() {
		return this.http.post<string>(
			API_URL.lastPpgEdit,
			null,
			{ responseType: 'json' }
		);
	}

	getPpgDictionary() {
		return this.http.get<PpgDictionaryModel[]>(
			API_URL.getPpgDictionary,
			{ responseType: 'json' }
		);
	}


	// updatePpgListUser(nodeList: PpgNodeUpdate[]) {
	// 	return this.http.post<string>(
	// 		API_URL.updatePpgListUser,
	// 		nodeList,
	// 		{ responseType: 'json' }
	// 	);
	// }

	updatePpgCudUser(nodeList: CudNodeModel) {
		return this.http.post<string>(
			API_URL.updatePpgCudUser,
			nodeList,
			{ responseType: 'json' }
		);
	}


	// createNodeInfo(newNodeInfo: PpgNewInfoNode) {
	// 	return this.http.post<string>(
	// 		API_URL.createNodeInfo,
	// 		newNodeInfo,
	// 		{ responseType: 'json' }
	// 	);
	// }

	// deleteNodeInfo(nodeId: number) {
	// 	return this.http.post<string>(
	// 		API_URL.deleteNodeInfo + nodeId,
	// 		null,
	// 		{ responseType: 'json' }
	// 	);
	// }

	// deleteListNodeInfo(nodesId: number[]) {
	// 	return this.http.post<string>(
	// 		API_URL.deleteListNodeInfo,
	// 		nodesId,
	// 		{ responseType: 'json' }
	// 	);
	// }

	getBannedCat() {
		return this.http.get<string[]>(
			API_URL.getBannedCat,
			{ responseType: 'json' }
		);
	}

	updateBannedCat(bannedCat: string[]) {
		return this.http.post<string[]>(
			API_URL.updateBannedCat,
			bannedCat,
			{ responseType: 'json' }
		);
	}
	downloadPpg() {
		return this.http.get(
			API_URL.downloadPpg,
			{ responseType: 'text' }
		);
	}
}
