import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { AnimationOptions } from 'ngx-lottie';

@Component({
	selector: 'maia-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
	@Input('position') position: string = 'absolute';
	@Input('center') center: boolean = true;
	@Input('bg-color') bgColor: string = 'bg-green-light-700';
	@Input('bg-opacity') bgOpacity: string = 'bg-opacity-90';
	@Input('custom-class') customClass: string = '';
	@Input('loaderSize') loaderSize: number = 80;
	@HostBinding('class') classAttribute: string = '';

	options: AnimationOptions = {
		path: '/assets/images/spinner_m_tiny.json',
	};

	constructor() { }

	ngOnInit(): void {
		this.updateClassAttribute();
	}
	private updateClassAttribute() {
		const classes: string[] = [];
		classes.push(this.position);
		classes.push(this.bgColor);
		classes.push(this.bgOpacity);
		classes.push(this.customClass)
		if (this.center) classes.push('left-0 top-0 bottom-0 right-0');
		this.classAttribute = classes.join(' ');
	}
}
