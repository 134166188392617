import { createAction, props } from '@ngrx/store';
import { CalendarEventModel, CalendarModel, CreateCalendarModel, EventFormModel, GetEventsModel } from '@models/calendar.model';
import { EventImpl } from '@fullcalendar/core/internal';




export const GetCalendar = createAction(
    '[StoreCalendar] Get calendars ',
);
export const CreateCalendar = createAction(
    '[StoreCalendar] Create calendar ',
    props<{ value: CreateCalendarModel }>()
);
export const SetCalendar = createAction(
    '[StoreCalendar] Set calendar ',
    props<{ value: CalendarModel[] }>()
);

export const GetEvents = createAction(
    '[StoreCalendar] Get Events ',
    props<{ value: GetEventsModel }>()
);

export const SetEvents = createAction(
    '[StoreCalendar] Set Curr Events ',
    props<{ value: any[] }>()
);

export const EditEvent = createAction(
    '[StoreCalendar] Edit event ',
    props<{ value: EventImpl }>()
);

export const AddEvent = createAction(
    '[StoreCalendar] Set new event ',
    props<{ value: Date }>()
);

export const SaveEvent = createAction(
    '[StoreCalendar] Save current event',
    props<{ value: EventFormModel }>()
);

export const UpdateEvent = createAction(
    '[StoreCalendar] Update current event',
    props<{ value: EventFormModel }>()
);

export const DeleteEvent = createAction(
    '[StoreCalendar] Delete current event',
    props<{ value: number }>()
);

export const CalendarApiStatus = createAction(
    '[StoreCalendar] Calendar Api done',
    props<{ value: boolean | null }>()
);

export const resetCalendarState = createAction(
    '[StoreCalendar] reset calendar state'
);

// export const CreateEvent = createAction(
// 	'[StoreCalendar] Create new event ',
// 	props<{ value: CalendarEventModel }>()
// );


// export const CancelAdd = createAction(
// 	'[StoreCalendar] Cancel add event',
// 	props<{ value: number | string }>()
// );

// export const EditEvent = createAction(
// 	'[StoreCalendar] Edit event ',
// 	props<{ value: CalendarEventModel }>()
// );

// export const UpdateStoreEvent = createAction(
// 	'[StoreCalendar] Update current store event',
// 	props<{ value: { event: CalendarEventModel, id?: string | number } }>()
// );



// export const UpdateEvent = createAction(
// 	'[StoreCalendar] Update current event',
// 	props<{ value: EventFormModel }>()
// );





// export const UpdateDragResizeEvent = createAction(
// 	'[StoreCalendar] Update current event by drag or resize',
// 	props<{ value: { event: CalendarEventModel, newStart: Date, newEnd: Date | undefined } }>()
// );

// export const UpdateStoreEventDragResize = createAction(
// 	'[StoreCalendar] Update current store event by drag or resize',
// 	props<{ value: CalendarEventModel }>()
// );