import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as SearchActions from '../actions/search.action';
import { SearchService } from '@services/search.service';

@Injectable()
export class SearchEffects {
    loadSearch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SearchActions.LoadSearch),
            switchMap((action) => {
                return this.searchService.searchResults(action.value).pipe(
                    map((value: any[]) => {
                        return SearchActions.LoadSearchSuccess({
                            value,
                        });
                    }),
                    catchError((error) => {
                        return of(SearchActions.LoadSearchError({ error }));
                    })
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private searchService: SearchService
    ) { }
}
