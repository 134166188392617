import { createAction, props } from '@ngrx/store';
import { UknowCreateProfileModel, UknowReadModel } from '@models/uknow.model';


export const GetConvList = createAction(
    '[StoreUknow] get conversation list'
);


export const SetConvList = createAction(
    '[StoreUknow] set conversation list',
    props<{ convList: UknowReadModel[] }>()
);

export const ReadGuestConversation = createAction(
    '[StoreUknow] read guest conversation',
    props<{ conv: UknowCreateProfileModel }>()
);

export const SetGuestMsgs = createAction(
    '[StoreUknow] set guest conversation messages',
    props<{ msgs: any[] }>()
);