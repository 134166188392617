import { createReducer, on, Action } from '@ngrx/store';
import { UknowState, initialAppState } from '../app.interface';
import * as actions from '@store/actions/uknow.action';

export const reducer = createReducer(
    initialAppState.UknowState,
    on(actions.SetConvList, (state, payload): UknowState => {
        return {
            ...state,
            conversationList: [...payload.convList]
        }

    }),
    on(actions.ReadGuestConversation, (state, payload): UknowState => {
        return {
            ...state,
            selectedConversation: payload.conv.conversation_id!,
            loadChat: true
        }

    }),
    on(actions.SetGuestMsgs, (state, payload): UknowState => {
        return {
            ...state,
            currMessages: payload.msgs,
            loadChat: false
        }

    })
);

export function UknowReducer(state: UknowState | undefined, action: Action): UknowState {
    return reducer(state as UknowState, action as Action);
}