import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { inject } from '@angular/core';

export const showBackgroundStateProperty = 'showBackground';

export class GenericUtils {

	public static matchOtherValidator(otherControlName: string) {
		let thisControl: AbstractControl;
		let otherControl: AbstractControl;
		return function matchOtherValidate(control: AbstractControl) {
			if (!control.parent) {
				return null;
			}
			// Initializing the validator.
			if (!thisControl) {
				thisControl = control;
				otherControl = control.parent.get(otherControlName) as AbstractControl;
				if (!otherControl) {
					throw new Error(
						'matchOtherValidator(): other control is not found in parent group'
					);
				}
				otherControl.valueChanges.subscribe(() => {
					thisControl.updateValueAndValidity();
				});
			}
			if (!otherControl) {
				return null;
			}
			if (otherControl.value !== thisControl.value) {
				return {
					doesNotMatchOther: true,
				};
			}
			return null;
		};
	}

	public static patternValidator(
		regex: RegExp,
		error: ValidationErrors
	): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			if (!control.value) {
				// if control is empty return no error
				return null as any;
			}
			// test the value of the control against the regexp supplied
			const valid = regex.test(control.value);
			// if true, return no error (no error), else return error passed in the second parameter
			return valid ? (null as any) : error;
		};
	}

	public static isFieldInvalid(form: FormGroup, field: string) {
		return (
			!form.disabled && !form.get(field)?.valid && form.get(field)?.touched
		);
	}

	public static conditionalAnimationDuration(condition: boolean) {
		return condition
			? { value: '', params: { duration: 300 } }
			: { value: '', params: { duration: 0 } };
	}


	public static isAdult(): ValidatorFn {
		const datePipe = inject(DatePipe);
		return (control: AbstractControl): { isAdult: any } => {
			if (!control.value) {
				return null as any;
			}
			let nowYear = new Date().getFullYear();
			let selectYear = parseInt(datePipe.transform(control.value, 'YYYY-MM-dd')!.split('-')[0])
			if (('' + selectYear).length < 4 || selectYear > nowYear) {
				return { isAdult: 'Insert valid date' }
			} else if ((nowYear - selectYear) < 18) {
				return { isAdult: 'You are too young to use this service' }
			} else {
				return null as any;
			}
		};
	}
}


export function isString(data: unknown): data is string {
	return typeof data === 'string';
};

export function isNumber(data: unknown): data is number {
	return typeof data === 'number';
};
