import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TextToSpeechState } from '../app.interface';

export const selectChatFeature = createFeatureSelector<TextToSpeechState>('TextToSpeechState');


export const selectTtsEnabled = createSelector(
	selectChatFeature,
	(state: TextToSpeechState) => {
		return state.ttsEnabled
	}
);


export const selectTtsSamples = createSelector(
	selectChatFeature,
	(state: TextToSpeechState) => {
		return state.ttsSample
	}
);

export const selectPlayerCurrAnswer = createSelector(
	selectChatFeature,
	(state: TextToSpeechState) => {
		return state.ttsCurrAnswer
	}
);

export const selectPlayerStatus = createSelector(
	selectChatFeature,
	(state: TextToSpeechState) => {
		return state.ttsIsPlaying
	}
);

export const selectWsConnectionId = createSelector(
	selectChatFeature,
	(state: TextToSpeechState) => {
		return state.wsConnectionID
	}
);

export const getSelectBannedTts = createSelector(
	selectChatFeature,
	(state: TextToSpeechState) => {
		return state.ttsIgnoreAnswer
	}
);