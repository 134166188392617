import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AppRoutes } from '@utils/routes';

export const boardedGuard: CanActivateFn = (route, segments) => {
    let router = inject(Router)
    if (!localStorage.getItem('maia_boarded')) {
        router.navigate([`/${AppRoutes.ON_BOARD}`]);
        return false;
    }
    return true;
};