import { createAction, props } from '@ngrx/store';
import { ttsWsModel } from '@models/tts.model';


export const SetWsConnectionID = createAction(
    '[StoreTts] Set web socket connectionId ',
    props<{ value: string }>()
);

export const NewTtsStart = createAction(
    '[StoreTts] start new tts',
    props<{ value: ttsWsModel }>()
);

export const NewTts = createAction(
    '[StoreTts] recive new tts',
    props<{ value: ttsWsModel }>()
);

export const NewTtsEnd = createAction(
    '[StoreTts] end new tts',
    props<{ value: ttsWsModel }>()
);

export const SetBanTts = createAction(
    '[StoreTts] Ban current answer tts'
);

export const ClearTts = createAction(
    '[StoreTts] finish all tts sample and reset state'
);


export const EnablingTts = createAction(
    '[StoreTts] Enabling Tts response',
    props<{ value: boolean }>()
);

export const SetCurrAnswer = createAction(
    '[StoreTts] Set curr new answer ',
    props<{ value: number }>()
);

export const TtsPlaingStatus = createAction(
    '[StoreTts] set player status'
);

