import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import * as actions from '../actions/user.action';
import { AppRoutes } from '@utils/routes';
import { LocalStorageService } from '@services/locaStorage.service';




@Injectable()
export class UserEffects {
    userLoginSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LogInSuccess),
            tap(() => {
                this.router.navigate([AppRoutes.MYMAIA]);
            })
        )
    }, { dispatch: false });


    userLogOut$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.LogOut),
            tap(() => {
                this.localStorage.clear()
            }),
            tap(() => {
                window.location.reload();
            })
        )
    }, { dispatch: false });



    constructor(
        private actions$: Actions,
        private router: Router,
        private localStorage: LocalStorageService
    ) { }
}