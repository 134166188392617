<div class="md:rounded-xl flex w- flex-col overflow-hidden" [@slideContent]="animationState" (@slideContent.start)="onAnimationStart($event)" (@slideContent.done)="onAnimationDone($event)"
     [class.bg-red-50]="data.modalType === 'danger'"
     [class.bg-white]="data.modalType !== 'danger'"
     [ngClass]="data.modalContainerClasses">
    <div class="border-b text-center relative py-3 px-24" *ngIf="data.header" [ngClass]="data.modalType !== 'danger' ? 'border-gray-200' : 'border-red-700 text-red-700'">
        <button (click)="dialogRef.close()" *ngIf="data.backBtn" class="flex absolute left-3 top-2/4 text-2xl -translate-y-1/2 transition-colors" [ngClass]="data.modalType !== 'danger' ? 'text-green-light-700 hover:text-green-light-900' : 'text-gray-700 hover:text-gray-900'">
            <i class="icon-back"></i>
        </button>
        <span class="font-bold text-base">{{data.title}}</span>
        <button (click)="dialogRef.cancel()" *ngIf="data.closeBtn" class="flex absolute left-3 top-2/4 text-2xl -translate-y-1/2 transition-colors" [ngClass]="data.modalType !== 'danger' ? 'text-green-light-700 hover:text-green-light-900' : 'text-gray-700 hover:text-gray-900'">
            <i class="icon-close"></i>
        </button>
        <button (click)="dialogRef.close('postAction')" class="flex absolute right-3 top-2/4 text-sm -translate-y-1/2 transition-colors" [ngClass]="data.modalType !== 'danger' ? 'text-green-light-700 hover:text-green-light-900' : 'text-gray-700 hover:text-gray-900'">
            {{data.labelBtn}}
        </button>
    </div>
    <div class="w-full flex-1" [ngClass]="data.modalContentCasses">
        <ng-container cdkPortalOutlet></ng-container>
    </div>
</div>