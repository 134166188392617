import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable()
export class PreviousUrlService {
	private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public previousUrl$: Observable<string> = this.previousUrl.asObservable();

	constructor() { }

	setPreviousUrl(previousUrl: string) {
		this.previousUrl.next(previousUrl);
	}
}
