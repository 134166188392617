import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector, TemplateRef } from '@angular/core';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { ModalRef } from '@shared/components/modal/modal-ref';
import { MODAL_DATA } from '@shared/components/modal/modal-tokens';

// Each property can be overridden by the consumer
interface ModalOverlayConfig {
	panelClass?: string;
	hasBackdrop?: boolean;
	backdropClass?: string;
	positionX?: 'start' | 'center' | 'end';
	data?: any;
}

const DEFAULT_CONFIG: ModalOverlayConfig = {
	hasBackdrop: true,
	backdropClass: 'dark-backdrop',
	panelClass: '',
};

@Injectable()
export class ModalService {
	modalDialogRef!: ModalRef;
	// Inject overlay service
	constructor(private overlay: Overlay, private injector: Injector) { }

	open(
		component?: ComponentType<any> | TemplateRef<any>,
		config: ModalOverlayConfig = {}
	) {

		// Override default configuration
		const dialogConfig = { ...DEFAULT_CONFIG, ...config };

		// Returns an OverlayRef (which is a PortalHost)
		const overlayRef = this.createOverlay(dialogConfig);

		// Instantiate remote control
		this.modalDialogRef = new ModalRef(overlayRef);

		const overlayComponent = this.attachDialogContainer(
			overlayRef,
			dialogConfig,
			this.modalDialogRef,
			component
		);


		this.modalDialogRef.componentInstance = overlayComponent;

		if (component) this.modalDialogRef.inModalElement = component;


		// Subscribe to a stream that emits when the backdrop was clicked
		if (!dialogConfig.data.disableClose) overlayRef.backdropClick().subscribe((_) => this.modalDialogRef.close());

		// Return remote control
		return this.modalDialogRef;
	}

	private getOverlayConfig(config: ModalOverlayConfig): OverlayConfig {
		const positionStrategy = this.overlay
			.position()
			.global()
			.centerHorizontally()
			.centerVertically();

		if (config.positionX === 'start') positionStrategy.start();
		if (config.positionX === 'end') positionStrategy.end();
		const overlayConfig = new OverlayConfig({
			hasBackdrop: config.hasBackdrop,
			backdropClass: config.backdropClass,
			panelClass: config.panelClass,
			scrollStrategy: this.overlay.scrollStrategies.block(),
			positionStrategy,
		});

		return overlayConfig;
	}

	private createOverlay(config: ModalOverlayConfig) {
		// Returns an OverlayConfig
		const overlayConfig = this.getOverlayConfig(config);
		// Returns an OverlayRef
		return this.overlay.create(overlayConfig);
	}

	private attachDialogContainer(
		overlayRef: OverlayRef,
		config: ModalOverlayConfig,
		dialogRef: ModalRef,
		component: any
	) {



		const injector = this.createInjector(config, dialogRef);

		const containerPortal = new ComponentPortal(ModalComponent, null, injector);
		const containerRef: ComponentRef<ModalComponent> =
			overlayRef.attach(containerPortal);
		return containerRef.instance;
	}

	private createInjector(
		config: ModalOverlayConfig,
		dialogRef: ModalRef
	): Injector {
		const portalInjector = Injector.create({
			parent: this.injector,
			providers: [
				{ provide: ModalRef, useValue: dialogRef },
				{ provide: MODAL_DATA, useValue: config.data },
			],
		});
		return portalInjector;
	}


	modalGalleryWidth(screenW: number) {
		if (screenW >= 1000) {
			return 960
		} else {
			return screenW - 20
		}
	}


	closeModal() {
		this.modalDialogRef.close();
	}
}
