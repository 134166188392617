import { createAction, props } from '@ngrx/store';
import {
    AddContactParams,
    Contact,
    ContactType,
    DeleteContactListParams,
    DeleteContactParams,
} from '@models/contacts.model';

export const LoadContacts = createAction('[StoreContacts] LoadContacts');
export const LoadContactsSuccess = createAction(
    '[StoreContacts] LoadContactsSuccess',
    props<{ value: Contact[]; sort: 'name' | 'surname' }>()
);
export const LoadContactsError = createAction(
    '[StoreContacts] LoadContactsError',
    props<{ error: any }>()
);
export const AddContact = createAction(
    '[StoreContacts] Add Contact',
    props<{ value: AddContactParams }>()
);
export const AddContactSuccess = createAction(
    '[StoreContacts] Add Contact Success'
);
export const AddContactError = createAction(
    '[StoreContacts] Add Contact Error'
);
export const UpdateContact = createAction(
    '[StoreContacts] Update Contact',
    props<{ value: Contact }>()
);
export const UpdateContactSuccess = createAction(
    '[StoreContacts] Update Contact Success'
);
export const UpdateContactError = createAction(
    '[StoreContacts] Update Contact Error'
);
export const DeleteContact = createAction(
    '[StoreContacts] Delete Contact',
    props<{ value: DeleteContactParams }>()
);
export const DeleteContactSuccess = createAction(
    '[StoreContacts] Delete Contact Success'
);
export const DeleteContactError = createAction(
    '[StoreContacts] Delete Contact Error'
);
export const DeleteContactList = createAction(
    '[StoreContacts] Delete Contact List',
    props<{ value: DeleteContactListParams }>()
);
export const DeleteContactListSuccess = createAction(
    '[StoreContacts] Delete Contact List Success'
);
export const DeleteContactListError = createAction(
    '[StoreContacts] Delete Contact List Error'
);
export const SelectContact = createAction(
    '[StoreContacts] Select Contact',
    props<{ value: Contact }>()
);
export const ChangeStatusEditContact = createAction(
    '[StoreContacts] Change Status Edit Contact',
    props<{ value: boolean }>()
);
export const FilterContacts = createAction(
    '[StoreContacts] Filter Contacts',
    props<{ value: ContactType | null }>()
);
