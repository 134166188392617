import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DecodedTokenModel } from '@models/decodedToken.model';

export const selectUserFeature = createFeatureSelector<DecodedTokenModel>('UserState');

export const selectCurrentUser = createSelector(
    selectUserFeature,
    (state: DecodedTokenModel) => {
        return state
    }
);