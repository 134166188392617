import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

@Injectable()
export class AppInitService {
	constructor(
		private platform: Platform
	) { }
	Init() {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		return new Promise<void>((resolve, reject) => {
			if (params['nrd'] === 'true') {
				window.localStorage.setItem('maia_nrd', 'true');
			} else if (params['nrd'] && params['nrd'] !== 'true') {
				window.localStorage.removeItem('maia_nrd');
			}
			if (window.location.pathname.indexOf('/share/') >= 0 || window.location.pathname.indexOf('/uknow/') >= 0 || localStorage.getItem('maia_nrd')) {
				resolve();
			} else {
				if (this.platform.IOS) {
					window.location.href = 'https://apps.apple.com/it/app/maia/id6479750749';
					reject();
				} else if (this.platform.ANDROID) {
					window.location.href = 'https://play.google.com/store/apps/details?id=ai.mymaia.maia&hl=it&gl=US';
					reject();
				} else {
					resolve();
				}
			}

		});
	}
}
