import { ComponentType, OverlayRef } from '@angular/cdk/overlay';
import { ModalComponent } from './modal.component';
import { Observable, Subject, filter, take } from 'rxjs';
import { TemplateRef } from '@angular/core';

export class ModalRef {
	private _beforeClose = new Subject<void>();
	private _afterClosed = new Subject<void>();
	public _afterCancel = new Subject<void>();
	private _actionAfterClosed = new Subject<void>();

	componentInstance!: ModalComponent;

	inModalElement!: ComponentType<any> | TemplateRef<any>;


	constructor(private overlayRef: OverlayRef) { }

	close(action?: 'postAction'): void {
		this.componentInstance.animationStateChanged
			.pipe(
				filter((event) => event.phaseName === 'start'),
				take(1)
			)
			.subscribe(() => {
				this._beforeClose.next();
				this._beforeClose.complete();
				this.overlayRef.detachBackdrop();
			});

		this.componentInstance.animationStateChanged
			.pipe(
				filter(
					(event) => event.phaseName === 'done' && event.toState === 'leave'
				),
				take(1)
			)
			.subscribe(() => {
				this.overlayRef.dispose();
				action ? this._actionAfterClosed.next() : this._afterClosed.next();
				this._afterClosed.complete();
				this.componentInstance = null!;
			});

		this.componentInstance.startExitAnimation();
	}

	afterClosed(): Observable<void> {
		return this._afterClosed.asObservable();
	}

	actionAfterClosed(): Observable<void> {
		return this._actionAfterClosed.asObservable();
	}

	beforeClose(): Observable<void> {
		return this._beforeClose.asObservable();
	}

	cancel() {
		this.close();
		this._afterCancel.next();
	}
}
