import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UdriveState } from '../app.interface';

export const selectDriveFeature = createFeatureSelector<UdriveState>('UdriveState');

export const sidePanelOpen = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.sidePanelOpen
	}
);


export const storeIsLoading = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.loadStore
	}
);

export const storeFile = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.fileStored
	}
);

export const storeSize = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.sizeStore
	}
);

export const currPath = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.currPath
	}
);

export const currAction = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.currAction
	}
);

export const currInputValue = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.currInputValue
	}
);

// export const currInputValid = createSelector(
// 	selectDriveFeature,
// 	(state: UdriveState) => {
// 		return state.validInputValue
// 	}
// );

export const currEditedNode = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.currEditedNode
	}
);
export const currMultiSelectionStatus = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.multiSelectionActive
	}
);
export const currMultiSelectionList = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.multiSelectionItem
	}
);

export const canMultiselect = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.canMultiselect
	}
);

export const currExpandedFolders = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.expandedFoldersList
	}
);

export const currAttachedChat = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.detachNeeded
	}
);

export const urlUploadError = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.urlUploadError
	}
);

export const currUrlValidationStatus = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.urlValidationStatus
	}
);

export const currUploadCount = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.uploadCount
	}
);


export const currPermittedEmail = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.emailsAddress
	}
);
export const currFiles = createSelector(
	selectDriveFeature,
	(state: UdriveState) => {
		return state.currFiles
	}
);