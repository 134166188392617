import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
@NgModule({
	declarations: [
		LoaderComponent
	],
	imports: [
		CommonModule,
		LottieModule.forRoot({ player: () => player }),
		LottieCacheModule.forRoot()
	],
	exports: [
		LoaderComponent,
		LottieModule,
		LottieCacheModule
	]
})
export class LoaderModule { }