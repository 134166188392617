import { createReducer, on, Action } from '@ngrx/store';
import { ContactsState, initialAppState } from '../app.interface';
import * as ContactsActions from '../actions/contacts.action';


export const reducer = createReducer(
	initialAppState.ContactsState,
	on(ContactsActions.LoadContacts, (state) => {
		return {
			...state,
			isLoading: true,
		};
	}),
	on(ContactsActions.LoadContactsSuccess, (state, payload): ContactsState => {
		return {
			...state,
			isEditActive: false,
			// contacts: orderBy(
			// 	payload.value.filter((contact) => !contact.isDeleted),
			// 	payload.sort,
			// 	'asc'
			// ),
			// allContacts: orderBy(
			// 	payload.value.filter((contact) => !contact.isDeleted),
			// 	payload.sort,
			// 	'asc'
			// ),
			isLoading: false,
		};
	}),
	on(ContactsActions.LoadContactsError, (state) => {
		return {
			...state,
			isLoading: false,
		};
	}),
	on(ContactsActions.SelectContact, (state, payload): ContactsState => {
		return {
			...state,
			isEditActive: false,
			selectedContact: payload.value,
		};
	}),
	on(
		ContactsActions.ChangeStatusEditContact,
		(state, payload): ContactsState => {
			return {
				...state,
				isEditActive: payload.value,
			};
		}
	),
	on(ContactsActions.DeleteContactSuccess, (state): ContactsState => {
		return {
			...state,
			isEditActive: false,
			selectedContact: null,
		};
	}),
	on(ContactsActions.DeleteContactListSuccess, (state): ContactsState => {
		return {
			...state,
			isEditActive: false,
			selectedContact: null,
		};
	}),
	on(ContactsActions.FilterContacts, (state, payload): ContactsState => {
		return {
			...state,
			contacts: payload.value
				? state.allContacts.filter((c) => c.engine === payload.value)
				: state.allContacts,
		};
	})
);

export function ContactsReducer(
	state: ContactsState | undefined,
	action: Action
): ContactsState {
	return reducer(state as ContactsState, action as Action);
}
