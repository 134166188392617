import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		public authService: AuthService,
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.context.get(BYPASS_LOG)) {
			return next.handle(request);
		} else {
			if (this.authService.isLoggedIn()) {
				return next.handle(this.authService.addAuthToken(request))
			} else {
				return this.authService.refreshToken(request, next) as Observable<HttpEvent<any>>;
			}
		}
	}


	// addAuthToken(request: HttpRequest<any>) {
	// 	const token = localStorage.getItem("maia_access_token");
	// 	const subscription = JSON.parse(localStorage.getItem("maia_user_detail") as string).subscriptionKey;
	// 	const service: string = window.sessionStorage.getItem('maia_service') ? window.sessionStorage.getItem('maia_service')! : environment.service;
	// 	return request.clone({
	// 		setHeaders: {
	// 			'Authorization': `Bearer ${token}`,
	// 			'service': service,
	// 			'ocp-apim-subscription-key': subscription
	// 		}
	// 	})
	// }
}


export const BYPASS_LOG = new HttpContextToken(() => false);