import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Platform } from '@angular/cdk/platform';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

const MOBILE_MAX_WIDTH = 768; //Adjust as needed

@Injectable()
export class MobileViewService {
	private source = new BehaviorSubject(false);
	isMobile = this.source.asObservable();

	constructor(
		private platform: Platform,
		private breakpointObserver: BreakpointObserver
	) { }

	setIsMobile() {
		this.source.next(this.checkIfMobile());
	}


	checkIfMobile(): boolean {
		return this.platform.ANDROID || this.platform.IOS
	}

	// checkIfMobile(): boolean {
	// 	console.log(this.platform.ANDROID)
	// 	console.log(this.platform.IOS)

	// 	console.log(this.platform.ANDROID || this.platform.IOS)

	// 	if (this.platform.ANDROID || this.platform.IOS) {
	// 		return true;
	// 	} else {
	// 		return false
	// 	}
	// }
}
