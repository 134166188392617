import { createReducer, on, Action } from '@ngrx/store';
import { PersonalGraphState, initialAppState } from '../app.interface';
import * as PersonalGraphActions from '../actions/personalGraph.action';
import { CudNodeModel, PpgNewInfoNode, PpgNodeUpdate } from '@models/personalGraph.model';

export const reducer = createReducer(
    initialAppState.PersonalGraphState,
    on(
        PersonalGraphActions.SetPPGData,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                ppgData: payload.ppgData,
                ppgDictionary: payload.ppgDictionary,
                ppgDefaultCat: payload.ppgDefaultCat
            };
        }
    ),
    on(
        PersonalGraphActions.SetPPGBannedCat,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                ppgBannedCat: payload.value,
            };
        }
    ),
    on(
        PersonalGraphActions.SetPPGPersonalName,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                personalName: payload.value,
            };
        }
    ),
    on(
        PersonalGraphActions.SetPPGCurrNode,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                selectedNode: payload.value,
                selectedSubNode: 0,
            };
        }
    ),
    on(
        PersonalGraphActions.SetPPGCurrSubNode,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                selectedSubNode: payload.value,
            };
        }
    ),
    on(
        PersonalGraphActions.resetPPGCurrSubNode,
        (state): PersonalGraphState => {
            return {
                ...state,
                selectedSubNode: 0,
            };
        }
    ),
    on(
        PersonalGraphActions.SetPPGPath,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                graphPath: payload.value,
            };
        }
    ),
    on(
        PersonalGraphActions.SetPPGCurrPersonalData,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                currPersonalData: payload.value,
            };
        }
    ),
    on(
        PersonalGraphActions.SetPpgMultiple,
        (state, payload): PersonalGraphState => {
            let tmpUpdatelist = [...state.ppgMultipleUpdate.updateList];
            let tmpCreatelist = [...state.ppgMultipleUpdate.createList];
            let tmpDeletelist = [...state.ppgMultipleUpdate.deleteList];
            if (payload.value.updateList.length > 0) {
                payload.value.updateList.forEach((obj: PpgNodeUpdate) => {
                    let updateIndex = tmpUpdatelist.findIndex(object => {
                        return object.id === obj.id;
                    });
                    if (updateIndex >= 0) {
                        tmpUpdatelist[updateIndex] = obj
                    } else {
                        tmpUpdatelist = [...tmpUpdatelist, obj]
                    }
                })
            }
            if (payload.value.createList.length > 0) {
                payload.value.createList.forEach((obj: PpgNewInfoNode) => {
                    let createIndex = tmpCreatelist.findIndex(object => {
                        return object.subject === obj.subject && object.value === obj.value && object.label === obj.label;
                    });
                    if (createIndex >= 0) {
                        tmpCreatelist[createIndex] = obj
                    } else {
                        tmpCreatelist = [...tmpCreatelist, obj]
                    }
                })
            }
            if (payload.value.deleteList.length > 0) {
                payload.value.deleteList.forEach((obj: number) => {
                    if (!tmpDeletelist.includes(obj)) {
                        tmpDeletelist = [
                            ...tmpDeletelist,
                            obj
                        ]
                    }
                })
            }
            return {
                ...state,
                ppgMultipleUpdate: {
                    createList: tmpCreatelist,
                    updateList: tmpUpdatelist,
                    deleteList: tmpDeletelist,
                    id_user: payload.value.id_user
                }
            };
        }
    ),
    on(
        PersonalGraphActions.RemovePpgMultipleCreateByValue,
        (state, payload): PersonalGraphState => {
            let tmpCreate = [...state.ppgMultipleUpdate.createList];
            payload.value.forEach((str: string) => {
                tmpCreate = tmpCreate.filter((obj: PpgNewInfoNode) => {
                    return obj.value !== str
                })
            })
            return {
                ...state,
                ppgMultipleUpdate: {
                    updateList: [...state.ppgMultipleUpdate.updateList],
                    deleteList: [...state.ppgMultipleUpdate.deleteList],
                    createList: [...tmpCreate],
                    id_user: state.ppgMultipleUpdate.id_user
                }
            };
        }
    ),
    on(
        PersonalGraphActions.ResetPpgMultiple,
        (state): PersonalGraphState => {
            return {
                ...state,
                ppgMultipleUpdate: {
                    updateList: [],
                    deleteList: [],
                    createList: [],
                    id_user: ''
                }
            };
        }
    ),
    on(
        PersonalGraphActions.IsSavingPpgMultiple,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                ppgSavingNode: payload.value,
            };
        }
    ),
    on(
        PersonalGraphActions.ChangeNodeSideViewStatus,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                showNodeSide: payload.value,
            };
        }
    ),
    on(
        PersonalGraphActions.ChangeDataSideViewStatus,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                showDataSide: payload.value,
            };
        }
    ),
    on(
        PersonalGraphActions.TalkAbout,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                talkAbout: payload.value,
            };
        }
    ),
    on(
        PersonalGraphActions.TalkingAbout,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                talkAbout: payload.value,
            };
        }
    ),
    on(
        PersonalGraphActions.LastPPGUpdate,
        (state, payload): PersonalGraphState => {
            return {
                ...state,
                lastUpdate: payload.value,
            };
        }
    ),
    on(PersonalGraphActions.resetPPGState, (state): PersonalGraphState => {
        return {
            ...state,
            ...initialAppState.PersonalGraphState,
        };
    }
    ),

);

export function PersonalGraphReducer(
    state: PersonalGraphState | undefined,
    action: Action
): PersonalGraphState {
    return reducer(state as PersonalGraphState, action as Action);
}
