import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GoogleCalendarModel, GoogleCredential, GoogleToken } from '@models/google.model';
import { API_URL } from '@constants/api.constant';
import { SettingModel, UserInformations } from '@models/setting.model';

@Injectable({
	providedIn: 'root',
})
export class SettingService {
	constructor(private http: HttpClient) { }

	getUserInfo() {
		return this.http.get<UserInformations>(API_URL.getUser, { responseType: 'json' });
	}

	updateUserInfo(patchInfo: UserInformations) {
		return this.http.patch<string>(API_URL.updateUser, patchInfo, { observe: 'body' });
	}


	getSettingsByIdUser(idUser: string) {
		return this.http.get<SettingModel[]>(`${API_URL.getSettingsByIdUser}${idUser}`, { responseType: 'json' });
	}


	createSettings(obj: SettingModel) {
		return this.http.post<number>(API_URL.createSetting, obj, { responseType: 'json' });
	}

	updateSetting(obj: SettingModel) {
		return this.http.post<number>(API_URL.updateSetting, obj, { observe: 'body' });
	}


	googleToken(googleCredential: GoogleCredential) {
		return this.http.post<GoogleToken>(API_URL.googleToken, googleCredential, { observe: 'body' });
	}


	getGoogleCalendars(idUser: string) {
		return this.http.get<GoogleCalendarModel[]>(`${API_URL.getGoogleCalendar}${idUser}`, { observe: 'body' });
	}

	updateGoogleCalendars(idUser: string, calendars: GoogleCalendarModel[]) {
		return this.http.patch<any>(`${API_URL.updateGoogleCalendar}${idUser}`, calendars, { observe: 'body' })
	}


	revokeGoogleToken(idUser: string) {
		return this.http.post<string>(`${API_URL.googleRevokeToken}${idUser}`, null, { observe: 'body' });
	}

	revokePartialgoogleToken(googleCredential: GoogleCredential) {
		return this.http.post<GoogleToken>(API_URL.revokePartialgoogleToken, googleCredential, { observe: 'body' });
	}

	userReportCreate(formData: FormData) {
		return this.http.post<string>(API_URL.userReportCreate, formData, {
			observe: 'events'
		})
	}


	getLanguageByBrowser(): string {
		return navigator.language;
	}
}
