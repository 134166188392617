import { Injectable } from '@angular/core';
import { ChatSidebarItemModel } from '@models/chat.model';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private get maiaStorage(): Storage {
        return localStorage;
    }

    constructor() { }

    setItem(key: string, value: any) {
        this.maiaStorage.setItem(key, JSON.stringify(value));
    }
    getItem(key: string): any {
        try {
            JSON.parse(this.maiaStorage.getItem(key)!);
        } catch (e) {
            return this.maiaStorage.getItem(key)!
        }
        return JSON.parse(this.maiaStorage.getItem(key)!);
    }
    removeItem(key: string) {
        this.maiaStorage.removeItem(key);
    }
    clear(): void {
        this.maiaStorage.clear();
        window.sessionStorage.removeItem('maia_service');
    }
    removeById(item: string, idConv: string) {
        let chatlist = this.getItem(item);
        let newChatList = chatlist.filter((chat: ChatSidebarItemModel) => chat.convId !== idConv)
        this.setItem(item, newChatList);
    }
}
