import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { animationStateProperty } from '@utils/animations';

@Injectable()
export class NavigationService {
	public lastValue: number = 0;
	public newValue: number = 0;
	public animationValue: number = 0;
	constructor(private router: Router) {
		this.router.events.subscribe((event) => {
			if (event instanceof ActivationEnd) {
				const snapshot = event.snapshot;
				if (snapshot.data[animationStateProperty]) {
					this.lastValue = this.newValue;
					this.newValue = snapshot.data[animationStateProperty];
					this.animationValue = this.getAnimationNumber(
						this.lastValue,
						this.newValue
					);
				}
			}
		});
	}

	getAnimationNumber(lastValue: number, newValue: number) {
		let result = 0;
		if (lastValue && newValue) {
			result = 2;
			if (lastValue) {
				if (newValue > lastValue) {
					result = 2;
				} else {
					result = 1;
				}
			} else {
				result = 1;
			}
		}
		return result;
	}
}
