import { createReducer, on, Action } from '@ngrx/store';
import { initialAppState } from '../app.interface';
import * as actions from '../actions/user.action';
import { DecodedTokenModel } from '@models/decodedToken.model';

export const reducer = createReducer(
	initialAppState.UserState as DecodedTokenModel,
	on(actions.LogInSuccess, (state, payload): DecodedTokenModel => {
		return {
			...payload.userDetail,
			isBase: payload.userDetail.isPro === 'False' && payload.userDetail.isPlus === 'False' ? true : false,
			isPro: payload.userDetail.isPro === 'True' ? true : false,
			isPlus: payload.userDetail.isPlus === 'True' ? true : false,
		}
	}),
	on(actions.LogOut, (state): DecodedTokenModel => {
		return {
			...initialAppState.UserState
		}
	}),
);


export function UserReducer(state: DecodedTokenModel | undefined, action: Action): DecodedTokenModel {
	return reducer(state as DecodedTokenModel, action as Action);
}