import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { API_URL } from '@constants/api.constant';
import { environment } from '@environments/environment'
import { BYPASS_LOG } from '../interceptors/auth.interceptor';
import { LoginModel } from '@models/login.model';
import { AppRoutes } from '@utils/routes';
import { map } from 'rxjs';


export const authGuard: CanActivateChildFn = (route, segments) => {
	const authService = inject(AuthService);
	const router = inject(Router);
	const http = inject(HttpClient);
	const service: string = window.sessionStorage.getItem('maia_service') ? window.sessionStorage.getItem('maia_service')! : environment.service;
	if (authService.getToken() === null) {
		router.navigate([`/${AppRoutes.AUTH}`]);
		return false
	} else {
		if (!authService.isLoggedIn()) {
			const headers = new HttpHeaders().set('service', service);
			const context = new HttpContext().set(BYPASS_LOG, true);
			http.post<LoginModel>(API_URL.getAuthToken,
				{ grant_type: 'refresh_token', refresh_token: localStorage.getItem('maia_refresh_token') },
				{ headers, context }
			).pipe(
				map((res) => {
					authService.setToken(res);
					return true;
				})
			);
		}
		return true;
	}
};
