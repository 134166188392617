

export class TimeUtils {

	public static convertToTimestamp(date: string) {
		return new Date(date).getTime();
	}

	public static getUTC(date: string) {
		return -(new Date(date).getTimezoneOffset() * 60 * 1000);
	}

	public static dateSubtract(from: Date = new Date(), days: number = 0, months: number = 0, years: number = 0, hours: number = 0, minutes: number = 0, seconds: number = 0) {
		let newDate = new Date(from);
		if (years > 0 && years < newDate.getFullYear()) {
			newDate.setFullYear(newDate.getFullYear() - years);
		}
		if (months > 0 && months < 12) {
			newDate.setMonth(newDate.getMonth() - months);
		}
		if (days > 0 && days < 31) {
			newDate.setDate(newDate.getDate() - days);
		}
		if (hours > 0 && hours < 24) {
			newDate.setHours(newDate.getHours() - hours);
		}
		if (minutes > 0 && minutes < 60) {
			newDate.setMinutes(newDate.getMinutes() - minutes);
		}
		if (seconds > 0 && seconds < 60) {
			newDate.setSeconds(newDate.getSeconds() - seconds);
		}
		return newDate
	}

	public static dateAdd(from: Date = new Date(), days: number = 0, months: number = 0, years: number = 0, hours: number = 0, minutes: number = 0, seconds: number = 0) {
		let newDate = new Date(from);
		if (years > 0 && years < newDate.getFullYear()) {
			newDate.setFullYear(newDate.getFullYear() + years);
		}
		if (months > 0 && months < 12) {
			newDate.setMonth(newDate.getMonth() + months);
		}
		if (days > 0 && days < 31) {
			newDate.setDate(newDate.getDate() + days);
		}
		if (hours > 0 && hours < 24) {
			newDate.setHours(newDate.getHours() + hours);
		}
		if (minutes > 0 && minutes < 60) {
			newDate.setMinutes(newDate.getMinutes() + minutes);
		}
		if (seconds > 0 && seconds < 60) {
			newDate.setSeconds(newDate.getSeconds() + seconds);
		}
		return newDate
	}

	public static toIsoString(date: string, time: string) {
		return date + 'T' + time + ':00'
	}

	public static getUTCTime(date: string) {
		return TimeUtils.convertToTimestamp(date) + TimeUtils.getUTC(date)
	}
}