import { createAction, props } from '@ngrx/store';

export const LoadSearch = createAction(
    '[StoreSearch] LoadSearch',
    props<{ value: string }>()
);
export const LoadSearchSuccess = createAction(
    '[StoreSearch] LoadSearchSuccess',
    props<{ value: any[] }>()
);
export const LoadSearchError = createAction(
    '[StoreSearch] LoadSearchError',
    props<{ error: any }>()
);
export const ChangeSearchViewStatus = createAction(
    '[StoreSearch] Change Search View Status',
    props<{ value: boolean }>()
);
