import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route } from "@angular/router";
import { Observable, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class FlagBasedPreloadingStrategy extends PreloadingStrategy {
	boarded = localStorage.getItem('maia_boarded');
	userLogged = localStorage.getItem('maia_user_detail');
	preload(route: Route, load: () => Observable<any>): Observable<any> {
		return (route.data?.["preload"] === true && this.boarded && this.userLogged) ? load() : of(null);
	}
}