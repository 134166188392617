import { createReducer, on, Action } from '@ngrx/store';
import { SearchState, initialAppState } from '../app.interface';
import * as SearchActions from '../actions/search.action';

export const reducer = createReducer(
  initialAppState.SearchState,
  on(SearchActions.LoadSearch, (state) => {
    return {
      ...state,
    };
  }),
  on(SearchActions.LoadSearchSuccess, (state, payload): SearchState => {
    return {
      ...state,
      searchResult: payload.value,
    };
  }),
  on(SearchActions.LoadSearchError, (state) => {
    return {
      ...state,
    };
  }),
  on(SearchActions.ChangeSearchViewStatus, (state, payload): SearchState => {
    return {
      ...state,
      showSearchView: payload.value,
    };
  })
);

export function SearchReducer(
  state: SearchState | undefined,
  action: Action
): SearchState {
  return reducer(state as SearchState, action as Action);
}
