import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from '@constants/api.constant'
import { UdriveFileTreeModel, UdriveMailModel, UdriveQuickActionCacheModel, UdriveQuickActionModel, UdriveReadResponseModel } from '@models/udrive.model';

@Injectable({
	providedIn: 'root',
})
export class UdriveService {
	constructor(private http: HttpClient) { }


	uploadFile(formData: any) {
		return this.http.post<UdriveFileTreeModel[]>(API_URL.udriveUploadFile, formData, { responseType: 'json' })
	}

	uploadUrl(newUrl: { file_name: string, path: string }) {
		return this.http.post<UdriveFileTreeModel>(API_URL.udriveUploadUrl, newUrl, { responseType: 'json' })
	}

	readDrive() {
		return this.http.get<UdriveReadResponseModel>(API_URL.udriveRead, { responseType: 'json' })
	}

	addFolder(newFolder: { file_name: string, path: string }) {
		return this.http.post<number>(API_URL.udriveCreateFolder, newFolder, { responseType: 'json' })
	}

	deleteItem(fileId: number) {
		return this.http.post<string[]>(`${API_URL.udriveDeleteItem}${fileId}`, null, { responseType: 'json' })
	}

	deleteListItems(fileIds: number[]) {
		return this.http.post<string[]>(API_URL.udriveDeleteListItems, fileIds, { responseType: 'json' })
	}

	renameFolder(item: UdriveFileTreeModel) {
		return this.http.post<number>(API_URL.udriveRenameFolder, item, { responseType: 'json' })
	}

	renameFile(item: UdriveFileTreeModel) {
		return this.http.post<number>(API_URL.udriveRenameFile, item, { responseType: 'json' })
	}

	updateNotes(body: string[], fileId: number) {
		return this.http.post<number>(API_URL.udriveUpdateNotes + fileId, body, { responseType: 'json' })
	}

	moveItem(items: UdriveFileTreeModel[]) {
		return this.http.post<number[]>(API_URL.udriveMove, items, { responseType: 'json' })
	}

	readMail() {
		return this.http.get<UdriveMailModel[]>(API_URL.udriveEmailRead, { responseType: 'json' })
	}

	createMail(newMail: UdriveMailModel) {
		return this.http.post<number>(API_URL.udriveEmailCreate, newMail, { responseType: 'json' })
	}
	updateMail(updateMail: UdriveMailModel) {
		return this.http.post<number>(API_URL.udriveEmailUpdate, updateMail, { responseType: 'json' })
	}

	deleteMail(mailId: number) {
		return this.http.post<number>(`${API_URL.udriveEmailDelete}${mailId}`, null, { responseType: 'json' })
	}

	quickActions(action: UdriveQuickActionModel) {
		return this.http.post<any>(API_URL.udriveQuickActions, action, { responseType: 'json' })
	}

	saveInUdrive(body: { src: string; path: string }) {
		return this.http.post<any>(API_URL.udriveSaveInUdrive, body, { responseType: 'json' })
	}
}
