import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PersonalSettingsState } from '../app.interface';

export const selectPersonalSettingsFeature =
	createFeatureSelector<PersonalSettingsState>('PersonalSettingsState');

export const getMaiaApplicationPreferences = createSelector(
	selectPersonalSettingsFeature,
	(state: PersonalSettingsState) => {
		return state.maiaApplication;
	}
);

export const getGoogleConnStatus = createSelector(
	selectPersonalSettingsFeature,
	(state: PersonalSettingsState) => {
		return state.googleConnection.googleConnect;
	}
);

export const getGoogleTimezone = createSelector(
	selectPersonalSettingsFeature,
	(state: PersonalSettingsState) => {
		return state.googleConnection.timeZone;
	}
);

export const getGoogleCalendar = createSelector(
	selectPersonalSettingsFeature,
	(state: PersonalSettingsState) => {
		return state.googleConnection.googleCalendar;
	}
);


export const getInformation = createSelector(
	selectPersonalSettingsFeature,
	(state: PersonalSettingsState) => {
		return state.informations;
	}
);

export const getSourceManagement = createSelector(
	selectPersonalSettingsFeature,
	(state: PersonalSettingsState) => {
		return state.sourceManagement;
	}
);

export const getNotification = createSelector(
	selectPersonalSettingsFeature,
	(state: PersonalSettingsState) => {
		return state.notification;
	}
);

export const getContactSettings = createSelector(
	selectPersonalSettingsFeature,
	(state: PersonalSettingsState) => {
		return state.contactSettings;
	}
);


export const getMaiaMute = createSelector(
	selectPersonalSettingsFeature,
	(state: PersonalSettingsState) => {
		return state.maiaApplication.muteMaia;
	}
);

export const getCurrPath = createSelector(
	selectPersonalSettingsFeature,
	(state: PersonalSettingsState) => {
		return state.maiaApplication.currPath;
	}
);

// export const getPolicy = createSelector(
//     selectPersonalSettingsFeature,
//     (state: PersonalSettingsState) => {
//         return state.policy;
//     }
// );